<template>
  <utilisateur-form v-if="utilisateur" :initial-utilisateur="utilisateur" />
 
</template>

<script>
import UtilisateurForm from "@/components/UtilisateurForm";
export default {
  name: "UtilisateursEdit",
  components: {UtilisateurForm},
  data() {
    return {
      utilisateur : false
    }
  },
  mounted() {
    let _this = this;
    this.$store.dispatch('userModule/fetch', {
      utilisateur_id: this.$route.params.id
    }).then(function (response) {
      _this.utilisateur = response.data;
    })
  }
}
</script>

<style scoped>

</style>